import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import ScrollFade from '@benestudioco/react-scrollfade';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import React from 'react';
import useResizer from '@components/extra/useResizer';
import { EProgramIDs } from '@src/constants/data/programs/index';
import LeftArrow from '../../../images/v5/Launch/Carousel-Left.svg';
import RightArrow from '../../../images/v5/Launch/Carousel-Right.svg';
import { shuffle } from 'lodash';

SwiperCore.use([Navigation, Autoplay, Pagination]);

const TestimonialCarousel = ({
  testimonials,
  companyImagesData,
  testimonialImagesData,
  variant,
}) => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const swiperRef = React.useRef(null);

  const isMobile = useResizer();

  const settings = {
    slidesPerView: isMobile ? 1 : 3,
    id: 'testimonialCarousel',
    loop: true,
    spaceBetween: 10,
    speed: 1000,
    grabCursor: true,
    noSwiping: false,
    autoplay: {
      delay: 8000,
      disableOnInteraction: true,
    },
  };

  return (
    <div className="relative">
      <div className="mx-8 sm:mx-10">
        <Swiper
          ref={swiperRef}
          {...settings}
          slidesPerGroup={isMobile ? 1 : 3}
          className={`testimonialCarousal my-12 w-full`}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          autoplay
          modules={[Navigation, Autoplay, Pagination]}
          pagination={
            testimonials.length > 9
              ? false
              : {
                  clickable: true,
                  bulletActiveClass:
                    'swiper-pagination-bullet-active bulletClassActive',
                  bulletClass: `swiper-pagination-bullet first:ml-0 first:md:ml-0 ml-4 md:ml-8 w-3 h-3 ${
                    variant === EProgramIDs.LAUNCH ? 'bulletClass' : ''
                  }`,
                }
          }
        >
          <div>
            {shuffle(testimonials).map((el) => {
              return (
                <SwiperSlide key={el.name}>
                  <TestimonialCard
                    testimonial={el.testimonial}
                    image={el.image}
                    name={el.name}
                    role={el.role}
                    linkedInUrl={el.linkedInUrl}
                    company={el.company}
                    companyImagesData={companyImagesData}
                    testimonialImagesData={testimonialImagesData}
                    variant={variant}
                  />
                </SwiperSlide>
              );
            })}
          </div>
        </Swiper>
      </div>
      <>
        <div
          onClick={() => swiperRef.current.swiper.slidePrev()}
          ref={navigationPrevRef}
          className="absolute top-[20%] left-5 z-10 ml-1  -translate-x-1/2 transform cursor-pointer hover:opacity-75 sm:-left-12"
        >
          <LeftArrow
            className={`scale-50 ${
              variant === EProgramIDs.LAUNCH
                ? 'fill-launch-blue-200'
                : 'fill-black'
            } sm:scale-50`}
          ></LeftArrow>
        </div>{' '}
        <div
          onClick={() => swiperRef.current.swiper.slideNext()}
          ref={navigationNextRef}
          className="absolute top-[20%] right-3 z-10 -translate-x-1/2  transform cursor-pointer hover:opacity-75 sm:-right-14"
        >
          <RightArrow
            className={`scale-50   ${
              variant === EProgramIDs.LAUNCH
                ? 'fill-launch-blue-200'
                : 'fill-black'
            }  sm:scale-50`}
          ></RightArrow>
        </div>
      </>
      <style>{`
      .bulletClass {
        opacity: 1;
        background: #ffffff;
      }

      .bulletClassActive {
        opacity: 1;
        background: #00FFA3;
      }
      `}</style>
    </div>
  );
};

const TestimonialCard = ({
  testimonial,
  name,
  image,
  role,
  company,
  linkedInUrl,
  companyImagesData,
  testimonialImagesData,
  variant,
}) => {
  const companyImageSource = companyImagesData.find(
    (ele) => ele.name == company,
  );

  const companyImage = getImage(companyImageSource?.childImageSharp);

  const testimonialImageSource = testimonialImagesData.find(
    (ele) => ele.name == image,
  );
  const testimonialImage = getImage(testimonialImageSource?.childImageSharp);

  return (
    <div className="mx-6 max-w-[300px] md:mx-0 md:mb-8">
      <div
        className={`flex items-center justify-center rounded-[50px] ${
          variant === EProgramIDs.LAUNCH
            ? 'h-[200px] bg-launch-blue-900 font-light text-white'
            : 'h-[250px] bg-v5-green-100'
        } font-rubik md:rounded-[50px]`}
      >
        <p className="z-10 px-8 py-8 text-center leading-relaxed md:px-6">
          <span className="hidden md:block">
            <ScrollFade />
          </span>
          "{testimonial}"
        </p>
      </div>
      <div className="flex -translate-y-[36px] flex-col items-center justify-center">
        <div
          className={`h-[50px] w-[50px] rotate-45 rounded-lg ${
            variant === EProgramIDs.LAUNCH
              ? 'bg-launch-blue-900 text-white'
              : 'bg-v5-green-100'
          }`}
        ></div>

        <div
          className={`ml-1 ${
            variant === EProgramIDs.LAUNCH
              ? 'mt-2 h-[75px] w-[75px]'
              : 'h-[100px] w-[100px]'
          }`}
        >
          <GatsbyImage
            loading="lazy"
            image={testimonialImage}
            placeholder="blurred"
            alt={'Company'}
            className="w-ful h-full"
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>

        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center gap-2">
            <h3
              className={`font-manrope text-lg ${
                variant === EProgramIDs.LAUNCH ? 'text-white' : ''
              }`}
            >
              {name}
            </h3>
            <a href={linkedInUrl} target="_blank" rel="noreferrer">
              <div className={`h-[24px] w-[24px]`}>
                {variant === EProgramIDs.LAUNCH ? (
                  <StaticImage
                    loading="eager"
                    src={'../../../images/placements/Icons/LinkedIn-White.png'}
                    placeholder="blurred"
                    alt="Underline"
                    width={24}
                    imgStyle={{ objectFit: 'contain' }}
                  />
                ) : (
                  <StaticImage
                    loading="eager"
                    src={'../../../images/placements/Icons/LinkedIn-Green.png'}
                    placeholder="blurred"
                    alt="Underline"
                    width={24}
                    imgStyle={{ objectFit: 'contain' }}
                  />
                )}
              </div>
            </a>
          </div>
          <h4
            className={`font-manrope text-base  ${
              variant === EProgramIDs.LAUNCH
                ? 'text-launch-blue-100'
                : 'text-v5-neutral-400'
            }`}
          >
            {role}
          </h4>
          <div className={`h-[80px] w-[120px]`}>
            <GatsbyImage
              loading="lazy"
              image={companyImage}
              placeholder="blurred"
              alt={'Company'}
              className="w-ful h-full"
              imgStyle={{ objectFit: 'contain' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
